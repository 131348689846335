.App {
  background-color: #f3f8ff;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.hide {
  display: none;
}
.show {
  display: block;
}
.highlight {
  color: #ff0000;
  font-size: 0.94vw;
  font-weight: bold;
}
.arrow {
  height: 0.21vw;
  border: solid #004f9a;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 0.16vw;
  width: 0.21vw;
}
.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.pointer {
  cursor: pointer;
}

.selected-value {
  color: #21519b;
}

input[type="checkbox"]:enabled:checked {
  accent-color: #21519b;
  color: white;
}

/* Common CSS*/

.market-dropdown {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #dfe3e9;
  border-radius: 0.26vw;
  cursor: pointer;
  display: flex;
  height: 3.24vh;
  padding: 0 0.52vw;
  width: 15.63vw;
}

.market-dropdown-title {
  color: #3a3a3a;
  display: flex;
  font-size: 0.83vw;
}

.icon-down-arrow {
  margin-left: auto;
  width: 0.78vw;
}

.market-dropdown-list {
  background-color: #ffffff;
  border: 1px solid #dfe3e9;
  font-size: 0.83vw;
  margin-top: 4.09vw;
  max-height: 27.78vh;
  overflow-y: auto;
  position: absolute;
  width: 16.67vw;
}

.dropdown-list-item {
  color: #3a3a3a;
  padding: 0.46vh 0.52vw;
}

.dropdown-list-item:hover {
  background-color: #0653a021;
}

.active-slicer {
  color: #004f9a;
  font-weight: 500;
}

input[type="checkbox" i] {
  height: 0.83vw;
  width: 0.83vw;
}

input[type="radio" i] {
  height: 0.67vw;
  width: 0.67vw;
}

::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar:horizontal {
  height: 8px;
}
::-webkit-scrollbar-track {
  background-color: #d0d0d0;
  border-radius: 4px;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #00529c;
  border-radius: 4px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
