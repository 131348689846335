.table_div {
  border: 1px solid #e0e0e0;
}

.table_container thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

.table_col {
  background-color: #21519b;
  color: white;
}

.table_col,
.table_col_def {
  border: 1px solid #c5c5c5;
  font-size: 0.83vw;
  height: 1.04vw;
  min-width: 6.51vw;
  padding: 0.42vw;
  text-align: left;
  white-space: nowrap;
}

.table_col_def {
  position: relative;
}

.col-header {
  position: relative;
}

.col-header-label {
  align-items: center;
  display: flex;
}

.col-header-label {
  margin-right: 10px;
}

.failed {
  align-items: center;
  background-color: #eaa4a6;
  color: #000000;
}

.arrow-icon {
  cursor: pointer;
  margin-left: auto;
  width: 0.83vw;
  height: 0.83vw;
}

.failed-icon {
  cursor: pointer;
  float: right;
  height: 0.94vw;
  position: relative;
  top: 0.1vw;
}

.comment-col {
  padding: 0;
  width: auto;
}
.comment-section {
  border: none;
  background-color: transparent;
  font-size: 0.83vw;
  min-width: 300px;
  padding: 8px 0 0 8px;
  width: calc(100% - 10px);
}

.success {
  padding-left: 8px;
}

.processed-row-link {
  color: #21519b;
  cursor: pointer;
  text-decoration: underline;
}

/* Filter Popup Styles*/
.filter-popup {
  background: #ffffff;
  box-shadow: 3px 3px 3px 6px #00305e1f;
  color: #000000;
  max-height: 15.63vw;
  overflow-y: auto;
  position: absolute;
  right: 1%;
  top: 1.82vw;
  width: 98%;
  z-index: 50;
}

.filter-popup-layer {
  display: flex;
  flex-direction: column;
}

.filter-values {
  cursor: pointer;
  align-items: center;
  font-weight: 400;
  display: flex;
  padding: 8px 5px;
  width: calc(100% - 10px);
}

.value-check {
  cursor: pointer;
  margin-right: 8px;
  width: 15px;
}

.value-label {
  cursor: pointer;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 20px);
}

.filter-values:hover {
  background-color: #0653a021;
  color: #000000;
}

/* Comment Popup */
.comment-popup {
  background: #ffffff;
  box-shadow: 3px 3px 3px 6px #00305e1f;
  color: #000000;
  max-height: 7.63vw;
  width: 20.63vw;
  overflow-y: auto;
  padding: 15px;
  position: absolute;
  right: 23px;
  top: 0;
  z-index: 100;
}

.comment-popup label {
  display: block;
  position: relative;
  white-space: break-spaces;
  width: 100%;
  word-break: break-word;
}
