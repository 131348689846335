.search-popup-box {
  background-color: #dde1e8;
  border-radius: 5px;
  box-shadow: 0 5px 8px #0000000f;
  padding: 0.93vh 0.52vw;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 100;
}
.search-heading {
  color: #3a3a3a;
  font-size: 0.94vw;
  font-weight: 600;
  margin-bottom: 1.85vh;
  text-align: center;
}
.search-type {
  color: #000000;
  display: flex;
  font-size: 0.83vw;
  margin-bottom: 1.85vh;
}
.search-type-title {
  margin-right: 0.52vw;
}

.user-search-box {
  cursor: default;
}

.search-box {
  align-items: center;
  background-color: #ffffff;
  border: none;
  border-radius: 0.26vw;
  display: flex;
  font-size: 0.83vw;
  height: 3.24vh;
  outline: none;
  padding: 0 0.52vw;
  width: 15.63vw;
}

.search-icon {
  cursor: pointer;
  height: 1.04vw;
  width: 1.04vw;
}

.radio-option {
  display: flex;
  margin-right: 10px;
}

.user-details-section {
  color: #000;
  font-size: 0.83vw;
  margin: 1.85vh 0;
}

.user-info {
  display: flex;
  padding: 5px 0;
}

.user-title {
  display: block;
  text-align: end;
}

.user-info-value {
  text-align: left;
  padding-left: 5px;
}

.user-details-table {
  height: 37vh;
  margin-top: 1.85vh;
  overflow-y: auto;
}

.user-bu-details {
  background-color: #ffffff;
  border-collapse: collapse;
  width: calc(100% - 0.2vw);
}

.bu-role-row {
  border-bottom: 1px solid #c8c8c8;
  color: #000;
  font-size: 0.83vw;
}

.row-header {
  background-color: #ffffff;
  font-weight: 500;
  position: sticky;
  top: 0;
}

.row-value {
  border: 1px solid #c8c8c8;
  padding: 5px;
}

.search-popup-footer {
  bottom: 0.93vh;
  text-align: center;
  width: 100%;
}

.search-close-btn {
  background-color: #004f9a;
  border: 1px solid #004f9a;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-size: 0.83vw;
  margin-right: 1.56vw;
  padding: 0.46vh 0.78vw;
}

.access-note {
  font-size: 0.73vw;
}

/* Loader CSS Styles*/
.loader-ellipsis {
  display: inline-block;
  position: relative;
  width: 2.6vw;
  height: 2.6vw;
}
.loader-ellipsis div {
  position: absolute;
  top: 3.06vh;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #004f9a;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.loader-ellipsis div:nth-child(1) {
  animation: loader-ellipsis1 0.6s infinite;
}
.loader-ellipsis div:nth-child(2) {
  animation: loader-ellipsis2 0.6s infinite;
}
.loader-ellipsis div:nth-child(3) {
  left: 1.25vw;
  animation: loader-ellipsis2 0.6s infinite;
}
.loader-ellipsis div:nth-child(4) {
  left: 2.5vw;
  animation: loader-ellipsis3 0.6s infinite;
}
@keyframes loader-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes loader-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes loader-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
