.request-form {
  margin-top: 3.7vh;
  display: flex;
}

.gpid-details,
.market-details {
  display: flex;
  flex-direction: column;
  margin-right: 1.56vw;
}

.field-title {
  color: #000000;
  font-size: 0.94vw;
  font-weight: 600;
  line-height: 2.08vw;
  margin-bottom: 0.46vh;
}

.input-field {
  border: 1px solid #dfe3e9;
  border-radius: 0.26vw;
  color: #3a3a3a;
  font-size: 0.83vw;
  font-weight: 400;
  height: 3.24vh;
  padding: 0 0.52vw;
  width: 15.63vw;
}

.input-field:focus-visible {
  border: 1px solid #dfe3e9;
  outline: 0;
}

.btn-col {
  display: flex;
  align-items: end;
  margin-left: 1.04vw;
}

.submit-btn {
  background-color: #004f9a;
  border: 0;
  border-radius: 0.26vw;
  color: #ffffff;
  cursor: pointer;
  font-size: 0.94vw;
  height: 3.43vh;
  width: 10.42vw;
}

.submit-btn:disabled {
  cursor: default;
  background-color: #d1d1d1;
}
