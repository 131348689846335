.admin-section {
  padding: 1.04vw 0;
  position: relative;
}

.slicer-section {
  display: flex;
}

.market-slicer {
  margin-right: 1.3vw;
}

.admin-market-slicer {
  margin-top: 5px;
  height: 3.24vh;
}

.slicer-title {
  color: #000000;
  font-size: 0.94vw;
  font-weight: 600;
  line-height: 2.08vw;
}

.market-slicer-list {
  background-color: #ffffff;
  border: 1px solid #dfe3e9;
  font-size: 0.83vw;
  margin-top: 0;
  max-height: 27.78vh;
  overflow-y: auto;
  position: absolute;
  width: 16.67vw;
  z-index: 50;
}

.search-user-btn {
  align-items: end;
  cursor: pointer;
  display: flex;
  font-size: 0.83vw;
  margin-right: 10px;
}

.search-btn {
  align-items: center;
  background-color: #004f9a;
  border-radius: 5px;
  color: #ffffff;
  display: flex;
  height: 3.24vh;
  padding: 0 10px;
}

.search-slicer {
  align-items: end;
  display: flex;
}

.search-section {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #dfe3e9;
  border-radius: 0.26vw;
  padding-right: 5px;
  display: flex;
}

.sorting-icon {
  height: 0.78vw;
  margin-left: 0.52vw;
  width: 0.78vw;
}

.refresh-table-btn {
  align-items: end;
  display: flex;
  margin-left: auto;
  margin-right: 1.04vw;
}

.refresh-table-icon {
  align-items: center;
  display: flex;
  height: 3.24vh;
}

.admin-subtab {
  border-bottom: 1px solid #b8cde2;
  display: flex;
  flex-direction: row;
}

.subtab-title {
  background-color: #ffffff;
  cursor: pointer;
  font-size: 0.83vw;
  padding: 0.26vw 0.52vw;
}

.request-tab {
  border: 1px solid #b8cde2;
  border-top-left-radius: 0.26vw;
  border-bottom: 0;
  text-align: center;
  width: 6.77vw;
}

.upload-tab {
  border-right: 1px solid #b8cde2;
  border-top-right-radius: 0.26vw;
  border-top: 1px solid #b8cde2;
  text-align: center;
  width: 6.77vw;
}

.active-subtab {
  color: #004f9a;
  font-weight: 500;
}
