.popup-bgblur {
  background-color: #60606250;
  height: 100vh;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 100;
}

.popup-layer {
  background-color: #ffffff;
  border-radius: 5px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30vh;
  min-width: 300px;
  padding: 20px;
  opacity: 1;
  width: 30vw;
}

.close-icon {
  color: #0653a0;
  cursor: pointer;
  font-size: 1.04vw;
  height: 1.04vw;
  margin-left: auto;
  margin-right: 0;
  width: 1.04vw;
}

.close-icon > img {
  height: 100%;
  width: 100%;
}

.popup-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

.status-icon {
  height: 1.04vw;
  width: 1.04vw;
}

.status-text {
  margin-left: 5px;
  position: relative;
  top: -3px;
}

.upload-status {
  display: grid;
  justify-content: center;
  margin: auto;
  padding-bottom: 10px;
}

.single-message {
  text-align: center;
}

.messages {
  text-align: left;
}

.popup-status-message {
  text-align: center;
}

/* Request Popup Styles*/
.request-status-list {
  display: flex;
  text-align: left;
  font-size: 0.83vw;
}
