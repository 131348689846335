.selfservice-selection {
  display: flex;
  flex-direction: column;
  height: auto;
  padding-bottom: 0.6vw;
  position: relative;
  width: auto;
  z-index: 5;
}

.dropdown-row-ss {
  max-width: 65.08vw;
}

.template-selfservice {
  border-bottom: 1px solid #d1d1d1;
  padding: 1.56vw;
}

.download-template-ss {
  display: flex;
  position: relative;
}

.file-upload-section {
  margin: auto;
}

.upload-attachment-wrap {
  border: 1px solid #b8cde2;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 30px;
  text-align: left;
}

.attachment-row {
  align-items: center;
  display: flex;
  flex-direction: row;
  padding-bottom: 1.85vh;
}

.attachment-title {
  color: #000000;
  font-size: 0.94vw;
  font-weight: 600;
  padding-right: 1.56vw;
  width: 5.21vw;
}

.attachment-value {
  color: #000000;
  font-size: 0.94vw;
  padding-right: 1.56vw;
}

.selfservice-button {
  display: flex;
  margin-left: auto;
  margin-top: 2.78vh;
}

.selfservice-upload-btn {
  margin-right: 1.56vw;
}

.selfservice-btn {
  align-items: center;
  background-color: #004f9a;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  font-size: 0.83vw;
  justify-content: center;
  margin-left: auto;
  padding: 0.3vw 0.78vw;
  position: relative;
}

.selfservice-btn:disabled {
  background-color: #d1d1d1;
  cursor: default;
}

.download-btn-ss {
  align-items: center;
  display: flex;
  margin-left: 1.56vw;
}

.template-ss-btn {
  background-color: #004f9a;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  font-size: 0.83vw;
  padding: 0.35vw 0;
  width: 8.85vw;
}

.cancel-btn {
  width: 8.85vw;
}

.upload-btn-ss {
  padding: 0.3vw 2.11vw;
}
