.request-list {
  background-color: #ffffff;
  font-size: 0.83vw;
  margin-top: 2.78vh;
  max-height: 50.3vh;
  overflow-y: auto;
  position: relative;
}

.request-table {
  border-collapse: collapse;
  width: 100%;
}

.column-header,
.column-value {
  border-bottom: 1px solid #c8c8c8;
}

.column-header {
  background-color: #ffffff;
  position: sticky;
  top: 0;
}

.btn-row {
  background-color: #ffffff;
  position: sticky;
  bottom: 0;
}

.column-header-cell {
  color: #000000;
  font-weight: 500;
  padding: 1.85vh 0.78vw 0.46vh;
}

.column-value-cell {
  color: #000000;
  padding: 1.85vh 0.78vw 0.46vh;
}

.checkbox-header {
  display: flex;
}

.request-submit-section {
  border-top: 1px solid #c8c8c8;
  display: flex;
  font-size: 0.83vw;
  padding-top: 1.39vh;
  position: relative;
}

.approve-btn-cell,
.reject-btn-cell {
  cursor: pointer;
  padding: 0.93vh;
}

.approve-btn-cell {
  margin-left: auto;
}

.approve-btn,
.reject-btn {
  align-items: center;
  border-radius: 0.26vw;
  display: flex;
  font-weight: 500;
  justify-content: center;
  width: 6.25vw;
  padding: 0;
}

.approve-dropdown-btn,
.reject-dropdown-btn {
  box-shadow: 0px 3px 20px #34343414;
  background-color: #ffffff;
  cursor: pointer;
  font-weight: 600;
  width: 5.21vw;
  padding: 0.52vw;
  position: absolute;
  text-align: left;
  top: 3.39vw;
}

.approve-btn {
  background-color: #1ad8c2;
  color: #000000;
}

.approve-req-btn,
.reject-req-btn {
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 0.93vh 0.52vw;
  width: 4.65vw;
}

.approve-req-icon,
.reject-req-icon {
  align-items: center;
  border-left: 0.03vw solid #000000;
  justify-content: center;
  display: flex;
  padding: 0.52vw;
  width: 1.56vw;
}

.approve-req-arrow-icon,
.reject-req-arrow-icon {
  height: 0.78vw;
  width: 0.78vw;
}

.reject-btn {
  background-color: #feabb3;
  color: #515151;
}

.active-btn {
  color: #004f9a;
}
