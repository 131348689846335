.home-section {
  width: 100%;
  min-height: calc(100vh - 60px);
  height: auto;
}

/* Top Tab section for admin user */
.tab-section {
  display: flex;
  padding: 15px 5%;
}

.nav-tab {
  background-color: #ffffff;
  border: 1px solid #d1d1d1;
  border-radius: 0.26vw;
  color: #004f9a;
  cursor: pointer;
  font-size: 0.83vw;
  margin-right: 1.56vw;
  padding: 5px 15px;
}

.selected-tab {
  background-color: #004f9a;
  border: 1px solid #004f9a;
  color: white;
}

/* Home page dashboard */
.home-title {
  color: #58585a;
  font-size: 1.04vw;
  padding: 20px 5%;
  text-align: left;
}

.home-title .user-name {
  color: #00529c;
  font-weight: 600;
}

.home-title .selection-text {
  border-bottom: 1px solid #b8cde2;
  font-size: 0.83vw;
  font-weight: normal;
  margin-top: 1.04vw;
  padding-bottom: 0.52vw;
}

.selection-text {
  display: flex;
  flex-direction: column;
}

.selection-upload-text {
  margin-top: 10px;
}

.selection-dropdown {
  border-bottom: 1px solid #b8cde2;
  display: flex;
  flex-direction: row;
  height: auto;
  padding-bottom: 1.6vw;
  position: relative;
  width: auto;
  z-index: 5;
}

.admin-upload-section {
  border: 0;
  margin-top: 0.93vh;
}

.dropdown-list {
  float: left;
  margin-right: 1.04vw;
  height: 3.91vw;
}

.dropdown-title {
  color: #000000;
  font-size: 0.94vw;
  font-weight: 600;
  line-height: 2.08vw;
}

.dropdown-list div label {
  display: block;
  font-size: 0.83vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dropdown-col1 {
  width: 11.02vw;
}

.dropdown-col1 div label {
  width: 11.46vw;
}

.dropdown-col2 {
  width: 10.85vw;
}

.dropdown-col2 div label {
  width: 7.81vw;
}

.dropdown-col3 {
  width: 10.42vw;
}

.dropdown-col3 div label {
  width: 9.15vw;
}

.dropdown-col4 {
  width: 14.58vw;
}

.dropdown-col4 div label {
  width: 13.54vw;
}

.dropdown-row2-cols {
  width: 9vw;
}

.full-override-file-section {
  width: 10vw;
}

.dropdown-row {
  max-width: 52.08vw;
}

.selection-dropdown .select-dropdown {
  padding: 5px 0;
}

.selection-dropdown .slicer-element {
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  background-color: #ffffff;
  display: flex;
  font-size: 0.94vw;
  height: 1.09vw;
  position: relative;
  width: 100%;
}

.generation-select label,
.market-select label,
.file-select label,
.metric-select label {
  margin-left: 0.26vw;
}

.generation-select i,
.market-select i,
.file-select i,
.metric-select i {
  cursor: pointer;
  margin-right: 0.42vw;
  margin-left: auto;
}

.generation-option,
.market-option,
.file-option,
.metric-option {
  background-color: #ffffff;
  margin-top: 1.56vw;
  max-height: 13.02vw;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  z-index: 110;
}

.option-value {
  color: #000000;
  font-size: 0.83vw;
  padding: 0.26vw;
  white-space: nowrap;
}

.option-value:hover {
  background-color: #0653a021;
  color: #000000;
}

.dropdown-icon {
  float: right;
  margin-top: 3px;
}

.year-period-txt input {
  font-size: 0.94vw;
  padding: 0.36vw 0;
  width: 100%;
  border: none;
}

.overlay {
  cursor: default;
  opacity: 0.5;
}

.logs-template-btn {
  width: 10.42vw;
}

.template-btn button,
.template-btn-upload button,
.logs-template-btn button {
  background-color: #004f9a;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  font-size: 0.83vw;
  font-weight: 500;
  height: 1.72vw;
  min-width: 8.85vw;
  padding: 0 0.78vw;
}

.download-btn {
  height: 3.91vw;
}

.template-btn button {
  height: 1.72vw;
  margin-left: 1.04vw;
  position: relative;
  top: 2.08vw;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.download-template-row {
  display: flex;
  height: 3.91vw;
  position: relative;
}

.left-btn {
  left: -22.93vw;
}

.download-btn button:disabled,
.download-template-row button:disabled {
  cursor: default;
  background-color: #d1d1d1;
}

.upload-parent-section {
  display: flex;
}

.upload-section {
  height: 1.72vw;
  margin-left: auto;
  margin-right: 0.99vw;
  text-align: center;
}

.upload-section input[type="file"],
.upload-section-ss input[type="file"] {
  color: #b8b8b8;
  font-size: 0.83vw;
  left: 0.42vw;
  position: absolute;
  top: 0.52vw;
  visibility: hidden;
  z-index: -5;
}

.upload-btn-wrap {
  margin-left: 1.04vw;
  position: relative;
  text-align: left;
  width: auto;
}

.custom-upload {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  cursor: default;
  display: flex;
  font-size: 0.83vw;
  height: 1.61vw;
  position: relative;
  width: 20.83vw;
}

.upload-section input#file-upload-button,
.upload-section-ss input#file-upload-button {
  background-color: #004f9a;
  color: #ffffff;
}

.upload-btn {
  align-items: center;
  background-color: #004f9a;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  height: 1.04vw;
  justify-content: center;
  margin-left: auto;
  padding: 0.3vw 0.78vw;
  position: relative;
  width: 5.33vw;
}

.error-message {
  color: #ff0000;
  font-size: 0.73vw;
}

.disabled-tab {
  color: #d1d1d1;
}

.disable-input {
  cursor: default;
  background-color: #d1d1d1;
}

.disable-input label {
  cursor: default;
}

.upload-icon {
  cursor: pointer;
  height: 2.08vw;
}

.upload-text {
  cursor: pointer;
  font-size: 0.83vw;
  font-weight: 500;
  position: relative;
}

.selected-file-label {
  cursor: default;
  display: block;
  padding-left: 0.26vw;
  position: relative;
  max-width: 13.54vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.removed-file {
  cursor: pointer;
  margin-left: 0.52vw;
  width: 1.04vw;
}

.flex-row-btn {
  display: flex;
  padding-top: 0.54vw;
}

.refresh-btn {
  float: right;
  margin-left: auto;
  order: 2;
  position: relative;
}

.refresh-icon {
  cursor: pointer;
  height: 0.94vw;
  margin-right: 0.52vw;
  position: relative;
  top: 0.1vw;
}

.refresh-label {
  color: #00529c;
  cursor: pointer;
  font-weight: 500;
}

.table_segment {
  height: calc(100vh - 480px);
  max-width: 100vw;
  min-height: 18.73vw;
  padding: 0 5% 10px;
  position: relative;
  top: -1.56vw;
}

.table_container {
  background-color: #ffffff90;
  border-collapse: collapse;
}

.table_header {
  color: #000000;
  font-size: 0.94vw;
  font-weight: 500;
}

.table-section {
  height: 100%;
  margin-top: 1.04vw;
  overflow: auto;
  width: 100%;
}

.request-access {
  background-image: url("../Images/icon-access-denied.svg");
  background-position: center center;
  background-repeat: no-repeat;
}

/* loader */
.loader {
  height: 100vh;
  background-color: #00000090;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 50;
}

.loader-icon {
  animation: spin 1s linear infinite;
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #00529c;
  border-left: 3px solid #00529c;
  height: 30px;
  left: calc(50vw - 15px);
  position: absolute;
  margin: auto;
  top: calc(50vh - 15px);
  width: 30px;
}

.loader-text {
  color: #ffffff;
  left: 50vw;
  position: absolute;
  top: calc(50vh + 30px);
  transform: translate(-50%, 0);
}

.outer-loader {
  animation: spin 1s linear infinite;
  height: 40px;
  left: calc(50vw - 20px);
  top: calc(50vh - 20px);
  width: 40px;
}

.inner-loader {
  animation: reverse-spin 1s linear infinite;
  height: 30px;
  left: calc(50vw - 15px);
  top: calc(50vh - 15px);
  width: 30px;
}

.rotate-icon {
  animation: spin 0.5s linear infinite;
}

/* React Select Classes */
.react-select__control {
  min-height: 1.72vw;
}

.react-select__menu {
  font-size: 0.83vw;
  z-index: 10;
}

.react-select__indicators {
  height: 1.72vw;
}
.react-select__placeholder {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes reverse-spin {
  100% {
    -moz-transform: rotate(-360deg);
  }
}

@-webkit-keyframes reverse-spin {
  100% {
    -webkit-transform: rotate(-360deg);
  }
}

@keyframes reverse-spin {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
@media only screen and (max-width: 1350px) {
  .home-title,
  .tab-section {
    padding: 1.85vh 3%;
  }
  .table_segment {
    padding: 0 3% 10px;
  }
}
