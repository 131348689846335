#header {
  align-items: center;
  background-color: #00529c;
  box-shadow: 0px 3px 12px #0000000f;
  box-sizing: border-box;
  display: flex;
  height: 60px;
  justify-content: space-between;
  width: 100%;
  z-index: 45;
}

.header-sticky {
  position: sticky;
  top: 0;
  left: 0;
}

#header-logo {
  align-items: center;
  display: flex;
}

#header-logo {
  margin-left: 20px;
}

#pepsico-logo {
  height: 25px;
}

#header-title {
  align-items: center;
  display: flex;
  color: #ffffff;
  opacity: 1;
  font-size: 26px;
}

#user-icon {
  align-items: center;
  display: flex;
  float: left;
  margin-right: 20px;
  border-radius: 50%;
  cursor: pointer;
  float: right;
  height: 32px;
  overflow: hidden;
  width: 32px;
}

#profile-pic {
  height: 32px;
}

#profile-name {
  background-color: #ffff;
  color: #26252b;
  font-size: 22px;
  font-weight: 500;
  height: 32px;
  line-height: 32px;
  text-align: center;
  width: 32px;
}

#profile-popup {
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #1a1a1a;
  box-shadow: 0px 0px 2px #0000003d, 0px 8px 16px #00000047;
  color: #1a1a1a;
  font-size: 0.83vw;
  font-weight: 600;
  padding: 5px;
  position: absolute;
  right: 5px;
  text-align: left;
  top: 50px;
  width: 15.63vw;
  z-index: 50;
}

.profile-popup-details {
  cursor: default;
  margin: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#logout-button {
  color: #00529c;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  opacity: 1;
  text-align: right;
}

#logout-button,
#user-email {
  font-size: 0.73vw;
}
