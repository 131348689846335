.popup-bgblur-ss {
  background-color: #60606250;
  height: 100vh;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 100;
}

.popup-layer-ss {
  background-color: #ffffff;
  border-radius: 5px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30vh;
  min-width: 300px;
  padding: 20px;
  opacity: 1;
  width: 30vw;
}

.popup-header {
  display: flex;
  margin-bottom: 10px;
}

.close-icon-ss {
  color: #0653a0;
  cursor: pointer;
  font-size: 1.04vw;
  height: 1.04vw;
  margin-left: auto;
  margin-right: 0;
  width: 1.04vw;
}

.close-icon-ss > img {
  height: 100%;
  width: 100%;
}

.popup-title-ss {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

.status-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.status-text-ss {
  margin-left: 5px;
  position: relative;
  text-align: center;
  top: -3px;
}

.request-status-list-ss {
  display: flex;
  font-size: 0.83vw;
  max-height: 150px;
  overflow-y: auto;
  text-align: left;
}

.file-list-ss {
  margin-top: 0;
}

.popup-status-single-message {
  margin: auto;  
}